import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../components/navbar";
import "./styles.css";
import $ from "jquery";
import React from 'react';
import { useNavigate, } from 'react-router-dom';
import PositionInfo from "../../components/positionInfo";
import JobPostingPopup from "../../components/jobPostingPopup";
import EditPositionPopup from "../../components/editPosition";

function dashboard() {
  const navigate = useNavigate();
  const [refresh_internship,setRefresh_internship]=useState(true);
  
  const routerLocation = useLocation();
  const state = routerLocation.state || {};
  const [isPopupOpen, setIsPopupOpen] = useState(state.isPopupOpen || false);
  const [jobTitle, setJobTitle] = useState(state.jobTitle || "");
  const [jobLink, setJobLink] = useState(state.jobLink || "");
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);

  const [positions, setPositions] = useState([]);

  const getCompanyInterviewsEndpoint = process.env.REACT_APP_GET_COMPANY_INTERVIEWS;

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");

    if (!companyId) {
      return;
    }

    $.ajaxSetup({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    // Fetch interviews
    $.get(
      `${getCompanyInterviewsEndpoint}?companyId=${companyId}`,
      (response) => {
        setPositions(response);

      }
    ).fail((err) => {
      console.error("Error fetching company interviews:", err);
    });
  }, [getCompanyInterviewsEndpoint]);

  const update_refresh =() =>{
    setRefresh_internship(!refresh_internship);
  }

  const handleOpenPopup = (title, link) => {
    setJobTitle(title);
    setJobLink(link);
    setIsPopupOpen(true);
  };

  const handleEditPopup = (position) => {
    setSelectedPosition(position);
    setIsEditPopupOpen(true);
  };

  const handleCloseEditPopup = () => {
    setIsEditPopupOpen(false);
  };

  return (
    <div>
      <Navbar showSidebar={true} />
      <div className="dashboard-container">
          <div>
            {isPopupOpen && (
              <JobPostingPopup
                jobTitle={jobTitle}
                jobLink={jobLink}
                onClose={handleClosePopup}
              />
            )}

            {isEditPopupOpen && (
              <EditPositionPopup
                position={selectedPosition}
                onClose={handleCloseEditPopup}
                update_refresh={update_refresh}
              />
            )}
        </div>
        <article className="glass-container">
          <div className="flex-row-container-header"> 
          <h1 className="title">Job Postings </h1>
          {positions.length !== 0 && (
            <button
              className="dashboard-add-job-button3"
              onClick={() => {
                const companyId = localStorage.getItem("companyId");
                navigate("/postPosition", { state: { companyId } });
              }}
            >
              + Job Posting
            </button>
          )}
          </div>
          <div className="dashboard-internship-title-container-main">
            <div className="dashboard-internship-info-title" style={{justifyContent: "center"}}>Status</div>
            <div className="dashboard-internship-info-title">Position</div>
            <div className="dashboard-internship-info-title">Applicants</div>
            <div className="dashboard-internship-info-title" style={{justifyContent: "center"}}>Job Link</div>
            <div className="dashboard-internship-info-title" style={{justifyContent: "center"}}>More</div>
          </div>

          <hr className="dashboard-divider-line" />

          <div className="dashboard-internships-info-container">
            {positions.length !== 0 ? (
              positions.map((position, index) => {
                return (
                  <PositionInfo
                    positionInfo={{
                      ...position,
                      onOpenPopup: handleOpenPopup,
                      onEditPopup: handleEditPopup  
                    }}
                    key={index}
                    update_refresh={update_refresh}
                  />
                );
              })
            ) : (
              <div className="dashboard-zero-internships">
                <p className="dashboard-zero-internships-text">
                  You currently do not have <em>any</em> active job postings. <br />
                  Start recruiting <strong>NOW</strong> by adding a new job posting below.
                </p>
                <button
                  className="dashboard-add-job-button2"
                  onClick={() => {
                    const companyId = localStorage.getItem("companyId");
                    navigate("/postPosition", { state: { companyId } });
                  }}
                >
                  + Job Posting
                </button>
              </div>
            )}
          </div>
          
        </article>
      </div>
    </div>
  );
}

export default dashboard;