import React, { useRef, useState } from "react";
import $ from "jquery";
import "./style.css";

const ForgotPassModal = ({ handleSetFalsePassModal }) => {
  const sendPasswordResetEmailEndpoint = process.env.REACT_APP_SEND_PASSWORD_RESET_EMAIL;
  
  const [incorrectPass, setIncorrectPass] = useState(false);
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const wrapperRef = useRef(null);

  const onInputChange = (e) => {
    setEmail(e.target.value);
  };

  const onExitModal = () => {
    handleSetFalsePassModal();
  };

  const checkUser = (e) => {
    e.preventDefault();
    $.ajaxSetup({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    let m = $.post(
      `${sendPasswordResetEmailEndpoint}`,
      JSON.stringify({ Email: email }),
      (data) => {
        setEmailSent(true);
      }
    ).fail((data2) => {
      if (data2.status === 400) {
        setIncorrectPass(true);
      }
    });
  };

  return (
    <div className="pass-modal-div">
      <div className="d-flex justify-content-center align-items-center login-modal__inner-div">
        <div className="forgot-pass_container" ref={wrapperRef}>
          <button onClick={onExitModal} className="forgot-pass__exit-btn">
            &#10005;
          </button>
          {!emailSent && (
            <>
              <h1 className="forgot-pass__header pt-4">Forgot Password?</h1>
              <p className="mt-4 forgot-pass__body">
                Enter the email address associated with your{" "}
                <b className="forgot-pass__bold">Intern Guys</b> account to
                receive an email link to reset your password.
              </p>
              <form className="ml-0" onSubmit={checkUser}>
                <input
                  type="email"
                  className="form-control forgot-pass__text-input mt-2"
                  placeholder="Email"
                  onChange={onInputChange}
                />
                {incorrectPass && (
                  <p className="forgot-pass__warning">
                    &#9888; &nbsp; The email address you entered doesn't match
                    any account. Check the spelling or try a different email.
                  </p>
                )}
                <div className="d-flex justify-content-center forgot-pass__reset-btn-div">
                  <button className="forgot-pass_reset-btn">
                    Send Reset Link
                  </button>
                </div>
              </form>
            </>
          )}
          {emailSent && (
            <p className="text-center forgot-pass__success-p pt-5">
              Thanks! An email has been sent to the given address with the steps
              to reset your password.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassModal;
