import React, {useState, useEffect} from "react";
import RecruiterAbout from "./RecruiterAbout";
import RecruiterSubscription from "./RecruiterSubcription";
import RecruiterContactInformation from "./RecruiterContactInformation";
import RecruiterBilling from "./RecruiterBilling";

import "../../Profile.css";


const RecruiterProfileBody = ({editPost, data, companyDescription, recruiterEmail, phoneNumber, companyLocation, companyLinkedin}) => {

  const [width, setWidth] = useState(window.innerWidth);
  function getCurrentDimension(){
    console.log(window.innerWidth);
    return {
      	width: window.innerWidth,
      	height: window.innerHeight,
        
    }
  }

  useEffect(() => {
    const updateWidth = () => {
      setWidth(getCurrentDimension().width)
    }
    window.addEventListener('resize', updateWidth);
    
    return(() => {
        window.removeEventListener('resize', updateWidth);
    })
  }, [width])




  return (
    <div>
      {width > 768 && (
        <div className="container-fluid mx-0 px-0 mt-4 pt-3 recruiter-profile-body__div">
          <div className="row">
            <div className="row col-md">
              <RecruiterAbout companyDescription={companyDescription} data={data} editPost={editPost} />
            </div>

            <div className="mt-5 row col-md-5 mt-md-0">
              {/* <RecruiterSubscription width={width} /> */}
            </div>
          </div>
          <div className="row mt-5">
            <div className="row col-md">
              <RecruiterContactInformation 
                editPost={editPost}
                recruiterEmail={recruiterEmail}
                phoneNumber={phoneNumber}
                companyLocation={companyLocation}
                companyLinkedin={companyLinkedin}
              />
            </div>
            <div className="mt-5 row col-md-5 mt-md-0">
              {/* <RecruiterBilling /> */}
            </div>
          </div>
        </div>
      )}
      {width <= 768 && (
        <div className="container-fluid mx-0 px-0 mt-4 pt-3 recruiter-profile-body__div">
          <div className="row">
            <RecruiterAbout companyDescription={companyDescription} editPost={editPost} />
          </div>
          <div className="row mt-5">
            <RecruiterContactInformation 
              editPost={editPost}
              recruiterEmail={recruiterEmail}
              phoneNumber={phoneNumber}
              companyLocation={companyLocation}
              companyLinkedin={companyLinkedin}
            />
          </div>
          <div className="mt-5 row">
            {/* <RecruiterSubscription /> */}
          </div>
          <div className="mt-5 row">
            {/* <RecruiterBilling /> */}
          </div>
        </div>
        
      )}
    </div>
  );
};

export default RecruiterProfileBody;
