import React, {useEffect, useState} from "react";
import "../../Profile.css";
import emailIcon from "../../../../images/email.svg"
import phoneIcon from "../../../../images/telephone.svg"
import linkIcon from "../../../../images/link.svg"
import linkedInIcon from "../../../../images/linkedin-black.svg"
import facebookIcon from "../../../../images/facebook-black.svg"
import igIcon from "../../../../images/insta-black.svg"
import twitterIcon from "../../../../images/twitter.svg"

const CompanyContactInformation = ({editPost, companyEmail, phoneNumber, companyWebsite}) => {
  const [editStatus,setEditStatus]=useState(false);
  const [emailInfo,setEmailInfo]=useState("internguys@gmail.com");
  const [phoneInfo,setPhoneInfo]=useState("(234) 567-8910");
  const [websiteInfo,setWebsiteInfo]=useState("Intern Guys");
  const handleEmailChange = (e)=>{
    setEmailInfo(e.target.value);
  }
  const handlePhoneChange = (e)=>{
    setPhoneInfo(e.target.value);
  }
  const handleWebsiteChange = (e)=>{
    setWebsiteInfo(e.target.value);
  }

  useEffect(()=>{
    if(companyEmail!==null){
      setEmailInfo(companyEmail);
    }
    if(phoneNumber!==null){
      setPhoneInfo(phoneNumber);
    }
    if(companyWebsite!==null){
      setWebsiteInfo(companyWebsite);
    }
  },[companyEmail,phoneNumber,companyWebsite]);
  const editPostCall=()=>{
    if(editStatus==false){
      return;
    }
    try{
      editPost({companyEmail:emailInfo,recruiterPhone:phoneInfo,website:websiteInfo});
    }
    catch(error){
      console.log(error,"edit error in company contact");
    }
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="d-flex flex-row justify-content-between align-content-center">
          <h1 className="profile--headers">Contact Information</h1>
          <a className="profile--link" onClick={()=>{setEditStatus(!editStatus); editPostCall()}}>
            {editStatus? 'Save':'Edit'}
          </a>
        </div>
      </div>
      <div className="row profile--body">
        <div className="d-flex flex-column align-items-start">
          <span className="d-flex flex-row profile__contact-info">
            <img src={emailIcon} alt="" className="profile__svg"/>

            {editStatus ? (<textarea value={emailInfo} onChange={(e)=>{handleEmailChange(e)}}/>): (<>&nbsp; &nbsp; &nbsp; {emailInfo}</>)}
          </span>
          <span className="d-flex flex-row profile__contact-info">
          <img src={phoneIcon} alt="" className="profile__svg"/>
            {editStatus ? (<textarea value={phoneInfo} onChange={(e)=>{handlePhoneChange(e)}}/>): (<>&nbsp; &nbsp; &nbsp; {phoneInfo}</>)}
          </span>
          <span className="d-flex flex-row profile__contact-info">
          <img src={linkIcon} alt="" className="profile__svg"/>
          {editStatus ? (<textarea value={websiteInfo} onChange={(e)=>{handleWebsiteChange(e)}}/>): (<>&nbsp; &nbsp; &nbsp; {websiteInfo}</>)}
          </span>

          <span className="d-flex flex-row profile__contact-info">
          <img src={linkedInIcon} alt="" className="profile__svg me-3"/>
          <img src={facebookIcon} alt="" className="profile__svg me-3"/>
          <img src={igIcon} alt="" className="profile__svg me-3"/>
          <img src={twitterIcon} alt="" className="profile__svg me-3"/>
          </span>
        </div>
      </div>
    </div>
  );
};

export default CompanyContactInformation;