import React from 'react';
import {useState, useEffect} from "react";
import Footer from "../../components/footer";
import NavigationBar from "../../components/navigation";
import Spinner from "../../components/spinner/spinner";
import BackArrow from "../../images/back.svg";
import "./style.css";
import Blogcard from "../../components/blogcard/BlogCard";
import { useParams, useNavigate  } from 'react-router-dom';

const BlogDetails = () => {
  const { blogId } = useParams();
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const decodedParams = decodeURIComponent(blogId);
 const id = decodedParams.slice(-36);
 const title = decodedParams.slice(0, -36 - 1);
 const [thumbnail, setThumbnail] = useState("");
 const [content, setContent] = useState("");
 const [date, setDate] = useState("");
 const [updatedDate, setUpdatedDate] = useState("");
 const [author, setAuthor] = useState("");
 const [blog, setBlog] = useState([]);
 const [recommended, setRecommended] = useState("");
 const maxLength = 200;

 const truncateDescription = (description, maxLength) => {
  if (description.length <= maxLength) return description;
     return description.substring(0, maxLength) + '...';
   };
 const redirectToBlog = (blog) => {
  const { blogId, title } = blog;
  const encodedTitle = encodeURIComponent(title);
  window.location.href = `/blog-details/${encodedTitle}-${blogId}`;
};
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

const goBack = () => {
  nav(-1);
}

 useEffect(() => {
  fetchBlogs();
}, [blogId]);




const fetchBlogs = async () => {
  try { 
    setIsLoading(true);
      const response = await fetch(process.env.REACT_APP_GET_BLOGS);
        if (response.ok) {
          const data = await response.json()    ;
          const recommendedBlogs = data.filter(blog => blog.blogId !== id).slice(0, 3);
          setRecommended(recommendedBlogs || []);
          const curr_blog = data.filter(blog => blog.blogId === id);
          setThumbnail(curr_blog[0].image);
          setContent(curr_blog[0].description);
          setDate(curr_blog[0].creationTimeStamp);
          setUpdatedDate(curr_blog[0].updatedTimeStamp);
          setAuthor(curr_blog[0].author);
        }
        setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
      console.log(error);
  }
}
  return (
    <div className=" w-full h-full flex flex-col bg-wallpaper">
      <NavigationBar/>
      {isLoading ?        <div className="w-full h-screen px-40 mobile-padding">
          <Spinner/>
           </div> : 
      <div className="w-full h-full px-40 mobile-padding">
      <div className="blog-details glass-container">
      <div className="flex-row mobile-flex bg-transparent rounded-2xl gap">
           <div className="w-full p-60 mobile-post mobile-padding">            
            <img src={BackArrow} className="back-btn" alt="Back" onClick={() => goBack()}/>
            
            <img src={thumbnail} alt="featured-img" className="w-full rounded-xl h-full" />
           </div>
           <div className="w-full flex-col justify-center p-60 mobile-post mobile-padding mobile-desc">
            <h1 className="font-bold mobile-header text-2xl">{title}</h1>
            <h4 className="font-bold text-blue"> {author} </h4>
            <p className="mb-0 font-bold"> Published {formatDate(date)}</p>
            <p className="mb-0 font-bold"> Updated {formatDate(updatedDate)} </p>
           </div>
        </div>
        <div dangerouslySetInnerHTML={{__html: content}} className="mt-5 text-left w-full h-full text-lg px-60 mobile-padding "/>
          <div className="flex flex-col"> 
          <h2 className="font-bold mb-10 mt-5 text-center"> Related Articles You Might Like </h2> 
          <div className="flex-row justify-between w-full flex-wrap gap-5 ">
        {recommended.length > 0 ? (
          recommended.map((blog, index) => (
            <Blogcard key={index}  author={blog.author} title={blog.title} date={formatDate(blog.creationTimeStamp)} description={truncateDescription(blog.description, maxLength)} image={blog.image} onClick={() => redirectToBlog(blog)}/>
          ))
        )
        : (
          <></>
        )
      }
        </div>
      </div>
    </div>   
    </div>
    }
    <Footer/>
  </div>
  );
};
export default BlogDetails;