import React from 'react'
import styles from './BotMessage.module.css';
import Logo from '../../../images/logo-circle.svg';
import Markdown from 'react-markdown'

const BotMessage = ({children}) => {
  return (
    <div className={styles.container}>
      <img src={Logo} className={styles.logo}/><Markdown className={styles.text}>{children}</Markdown>
    </div>
  )
}

export default BotMessage;
