import React, { useState, useEffect } from "react";
import CompanyAbout from "./CompanyAbout";
import CompanyOverview from "./CompanyOverview";
import CompanyContactInformation from "./CompanyContactInformation";
import CompanyInternshipTable from "./CompanyInternshipsTable";
import "../../Profile.css";

const CompanyProfileBody = ({editPost, companyDescription, companySize, companyField, dateFounded, companyLocation, companyEmail, phoneNumber, companyWebsite}) => {

  const [width, setWidth] = useState(window.innerWidth);

  function getCurrentDimension(){
    return {
      	width: window.innerWidth,
      	height: window.innerHeight
    }
}

  useEffect(() => {
    const updateWidth = () => {
      setWidth(getCurrentDimension().width)
    }
    window.addEventListener('resize', updateWidth);
    
    return(() => {
        window.removeEventListener('resize', updateWidth);
    })
  }, [width])



  return (
    <div>
      {width > 768 && (
        <div className="container-fluid mx-0 px-0 mt-4 pt-3 recruiter-profile-body__div">
          <div className="row">
            <div className="col me-5">
              <CompanyAbout companyDescription={companyDescription} editPost={editPost} />
            </div>

            <div className="col-5 mt-0">
              <CompanyOverview 
                editPost={editPost}
                companySize={companySize}
                companyField={companyField}
                dateFounded={dateFounded}
                companyLocation={companyLocation}
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col">
              <CompanyInternshipTable />
            </div>
            <div className="col-5 mt-0">
              <CompanyContactInformation
                editPost={editPost}
                companyEmail={companyEmail}
                phoneNumber={phoneNumber}
                companyWebsite={companyWebsite}
              />
            </div>
          </div>
        </div>
      )}
      {width <= 768 && (
        <div className="container-fluid mx-0 px-0 mt-4 pt-3 recruiter-profile-body__div">
          <div className="row">
            <CompanyAbout companyDescription={companyDescription} editPost={editPost} />
          </div>
          <div className="row mt-5">
            <CompanyOverview 
              editPost={editPost}
              companySize={companySize}
              companyField={companyField}
              dateFounded={dateFounded}
              companyLocation={companyLocation}
            />
          </div>
          <div className="mt-5 row">
            <CompanyContactInformation 
              editPost={editPost}
              companyEmail={companyEmail}
              phoneNumber={phoneNumber}
              companyWebsite={companyWebsite}
            />
          </div>
          <div className="mt-5 row">
            <CompanyInternshipTable />
          </div>
        </div>
        
      )}
    </div>
  );
};

export default CompanyProfileBody;
