import React, { useEffect, useState } from "react";
import $ from "jquery";
import "../../Profile.css";

const CompanyInternshipTable = (props) => {
  const getInternshipEndpoint = process.env.REACT_APP_GET_INTERNSHIP_ENDPOINT;
  const [internships, setInternships] = useState([]);
  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    $.ajaxSetup({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    $.get(`${getInternshipEndpoint}?CompanyId=${companyId}`, (res) => {
      setInternships(res);
    });
  }, []);

  return (
    <div className="company-internship-table_div">
      <div className="container-fluid profile--body list-profile-internships-container">
        <div className="row company-internship-table__row--head">
          <div className="col">Internships</div>
          <div className="col-4">Location</div>
        </div>
        {internships.map((internship, index) => {
          return (
            <div className="row company-internship-table__row">
              <div className="col d-flex align-items-center">{internship.internshipTitle}</div>
              <div className="col-4">
                {internship.internshipCity + ", " + internship.internshipState}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CompanyInternshipTable;
