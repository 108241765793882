import "./style.css";
import React from "react";

import { onSignInGoogle, onLoginInGoogle } from "../../authentication/GoogleAuth";

const ExternalAuth = ({ isLoggedIn }) => {

  return (
    <form className="external_form">
      <div className="external_icon_container">
        <GoogleSignIn className="google_sign_up" isLoggedIn={isLoggedIn}/>
      </div>
    </form>
  );
};

const GOOGLE_BUTTON_ID = "google-sign-in";
class GoogleSignIn extends React.Component {
  componentDidMount(){
    this.renderGoogleSignin();
  }
  componentDidUpdate(prevProps) {
    if (this.props.isLoggedIn !== prevProps.isLoggedIn) {
      this.renderGoogleSignin();
    }
  }
  renderGoogleSignin() {
    const callbackFunction = this.props.isLoggedIn ? onLoginInGoogle : onSignInGoogle;
    window.gapi.signin2.render(GOOGLE_BUTTON_ID, {
      onsuccess: callbackFunction,
    });
  }

  render() {
    return <div id={GOOGLE_BUTTON_ID} className = "google_btn"  />;
  }
}

export default ExternalAuth;
