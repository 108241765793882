import React, { useEffect, useState } from "react";
import "../../Profile.css";


const CompanyAbout = ({editPost, companyDescription}) => {
  const [editStatus,setEditStatus]=useState(false);
  const [profileAbout,setProfileAbout]=useState("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore magna aliqua. Eu ultrices vitae auctor eu augue ut lectus arcu bibendum. In iaculisnunc sed augue lacus viverra vitae congue. Vitae auctor eu augue ut lectus arcu. Neque convallis a cras semper auctor neque. Blandit massa enim nec dui nunc. Porta lorem mollis aliquam ut porttitor leo. Erat imperdiet sed euismod nisi porta lorem mollis. Risus nullam eget felis eget nunc. Condimentum vitae sapien pellentesque habitant morbi.")
  const handleProfileChange = (e)=>{
    setProfileAbout(e.target.value);
  }
  useEffect(()=>{
    if(companyDescription!==null){
      setProfileAbout(companyDescription);
    }
  },[companyDescription])

  const editPostCall=()=>{
    if(editStatus==false){
      return;
    }
    try{
      editPost({companyDescription:profileAbout});
    }
    catch(error){
      console.log(error,"edit error in company description");
    }

  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="d-flex flex-row justify-content-between align-content-center">
          <h1 className="profile--headers">About</h1>
          <a className="profile--link" onClick={()=>{setEditStatus(!editStatus); editPostCall()}}>
            {editStatus? 'Save':'Edit'}
          </a>
        </div>
      </div>
      <div className="row">
          {editStatus? 
            (<textarea className="profile--body" value={profileAbout} onChange={(e)=>{handleProfileChange(e)}}/>):
            (<p className="profile--body">{profileAbout} </p>)
          }
      </div>
    </div>
  );
};

export default CompanyAbout;