import React, { useState, useEffect } from "react";

import RecruiterProfileUser from "./components/RecruiterProfile/RecruiterProfileUser";
import RecruiterProfileBody from "./components/RecruiterProfile/RecruiterProfileBody";

import CompanyProfileUser from "./components/CompanyProfile/CompanyProfileUser";
import CompanyProfileBody from "./components/CompanyProfile/CompanyProfileBody";

import exitIcon from "../../images/exitIcon.svg";

import "./Profile.css";
import $ from "jquery";
const RecruiterProfile = (props) => {
  const [isRecruiter, setIsRecruiter] = useState(true);

  const getCompanyRecruiterEndpoint=process.env.REACT_APP_RECRUITER_GET_ENDPOINT;
  function getToken() {
    return window.localStorage.getItem("token");
  }
  const [companyDescription,setCompanyDescription]=useState(null);
  const [companyLocation,setCompanyLocation]=useState(null);
  const [companyLinkedin,setCompanyLinkedin]=useState(null);
  const [companyName,setCompanyName]=useState(null);
  const [companyEmail,setCompanyEmail]=useState(null);
  const [companyWebsite,setCompanyWebsite]=useState(null);
  const [companyField,setCompanyField]=useState(null);
  const [companySize,setCompanySize]=useState(null);
  const [dateFounded,setDateFounded]=useState(null);
  const [recruiterEmail,setRecruiterEmail]=useState(null);
  const [phoneNumber,setPhoneNumber]=useState(null);
  const [recruiterName,setRecruiterName]=useState(null);
  const [data,setData]=useState(null);

  const fetchData = () => {
    const userId = localStorage.getItem("userId");
    const companyId = localStorage.getItem("companyId");

    const userToken = getToken();
    $.ajaxSetup({
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${userToken}` ,
         Accept: "application/json",
      }
    });
     $.get(
      `${getCompanyRecruiterEndpoint}?UserId=${userId}`,
      (res)=>{
        try{
            setData(res);
            setPhoneNumber(res[0].phoneNumber || null);
            setRecruiterName(res[0].recruiterName || null);
        }
        catch(error){
          console.log(error);
          console.log("Error with get call");
        }
      });

      $.ajaxSetup({
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${userToken}` ,
           Accept: "application/json",
        }
      });
       $.get(
        `${process.env.REACT_APP_GET_COMPANY_ENDPOINT}?CompanyId=${companyId}`,
        (res)=>{
          try {
            setCompanyDescription(res[0].companyDescription);
            setCompanyLocation(res[0].companyHeadquarters);
            setCompanySize(res[0].companySize);
            setCompanyEmail(res[0].companyEmail);
            setRecruiterEmail(res[0].companyEmail); //
            setCompanyField(res[0].companyField);
            setCompanyWebsite(res[0].website);
            setDateFounded(res[0].dateFounded);
            setCompanyName(res[0].companyName);
            setCompanyLinkedin(res[0].linkedIn);
            
          } catch (error) {
            return;
          }
          
        }

    )
  }
  function recruiterBtnClicked() {
    setIsRecruiter(true);
    fetchData();
  }

  function companyBtnClicked() {
    setIsRecruiter(false);
    fetchData();
  }
  useEffect(()=>{
    fetchData();
  },[])

  const editPost=({
    userId= data["userId"],
    userLoginId= data["userLoginId"],
    recruiterName= data["userLogin"]["recruiterName"],
    recruiterEmail= data["userLogin"]["email"],
    recruiterPhone= data["userLogin"]["phoneNumber"],
    companyId= data["companyId"],
    companyName= data["companyName"],
    companyEmail= data["company"]["companyEmail"],
    companyHeadquarters= data["company"]["companyHeadquarters"],
    companyField= data["company"]["companyField"],
    companyClassification= data["company"]["companyClassification"],
    companySize= data["company"]["companySize"],
    dateFounded= data["company"]["dateFounded"],
    active= true,
    companyDescription= data["company"]["companyDescription"],
    website= data["company"]["website"],
    linkedIn= data["company"]["linkedIn"],
    instagram= data["company"]["instagram"],
    facebook= data["company"]["facebook"],
    tikTok= data["company"]["tikTok"],
    companyEmployeeNumber= data["company"]["companyEmployeeNumber"],
    companyAge= data["company"]["companyAge"]
  })=>{
    if(data!==null){
      try{
        const recruiterEditEndpoint=process.env.REACT_APP_RECRUITER_EDIT_ENDPOINT;
        $.ajaxSetup({
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        $.post(
          `${recruiterEditEndpoint}`,
          JSON.stringify({
            userId ,
            userLoginId ,
            recruiterName,
            recruiterEmail,
            recruiterPhone,
            companyId,
            companyName,
            companyEmail,
            companyHeadquarters,
            companyField,
            companyClassification,
            companySize,
            dateFounded,
            active,
            companyDescription,
            website,
            linkedIn,
            instagram,
            facebook,
            tikTok,
            companyEmployeeNumber,
            companyAge
          }),
          (data) => {
          }
        );
      }
      catch(err){
        console.log("error on edit post", err);
      }
    }
  }
  return (
    <div className="profile-div">
      {isRecruiter && (
        <div>
          <div className="profile-div__recruiter d-flex flex-row justify-content-between align-items-start">
            <RecruiterProfileUser onCompanyBtnClicked={companyBtnClicked} recruiterName={recruiterName} companyName={companyName} companyLocation={companyLocation}/>
            <button
              onClick={props.onExitClick}
              className="profile__exit-button"
            >
              <img src={exitIcon} alt = "exit-button" />
            </button>
          </div>

          <hr className="profile__hr" />
          <RecruiterProfileBody 
            editPost={editPost}
            data={data}
            companyDescription={companyDescription}
            recruiterEmail={recruiterEmail}
            phoneNumber={phoneNumber}
            companyLocation={companyLocation}
            companyLinkedin={companyLinkedin}
          />
        </div>
      )}
      {!isRecruiter && (
        <div>
          <div className="d-flex flex-row justify-content-between align-items-start">
            <CompanyProfileUser onRecruiterBtnClicked={recruiterBtnClicked} companyName={companyName}/>
            <button
              onClick={props.onExitClick}
              className="profile__exit-button"
            >
              <img src={exitIcon} alt = "exit-button" />
            </button>
          </div>

          <hr className="profile__hr" />
          <CompanyProfileBody
            editPost={editPost}
            companyDescription={companyDescription}
            companySize={companySize}
            companyField={companyField}
            dateFounded={dateFounded}
            companyLocation={companyLocation}
            companyEmail={companyEmail}
            phoneNumber={phoneNumber}
            companyWebsite={companyWebsite}
          />
        </div>
      )}
    </div>
  );
};

export default RecruiterProfile;
