import React, { useState } from 'react';
import styles from './MessageInput.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import useMediaQuery from '@mui/material/useMediaQuery';

export const MessageInput = ({ sendMessage }) => {
  const [message, setMessage] = useState('');
  const mobile = useMediaQuery('(max-width:600px)');

  const submitHandler = (e) => {
    e.preventDefault();
    sendMessage(message);
    setMessage("");
  }
  return (
    <>
      <div className={styles.container}>
        <form className={styles.inputContainer} onSubmit={submitHandler}>
          <input
            type="text"
            placeholder="Send a message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button
            className={styles.sendButton}
            type="submit"
          >
            {mobile ? <FontAwesomeIcon icon={faPaperPlane} /> : 'Send'}
          </button>
        </form>
      </div>
    </>
  );
};
