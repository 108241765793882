import React, {useState, useEffect} from "react";
import "../../Profile.css";

const RecruiterAbout = ({editPost, data, companyDescription}) => {
  const [editStatus,setEditStatus]=useState(false);
  const [profileAbout,setProfileAbout]=useState(companyDescription||"default string");
  const handleProfileChange = (e)=>{
    setProfileAbout(e.target.value);
  }
  
  useEffect(() => {
    if (companyDescription !== null) {
      setProfileAbout(companyDescription);
    }
  }, [companyDescription]);
  const editPostCall=()=>{
    if(editStatus==false){
      return;
    }
    try{
      editPost({companyDescription:profileAbout});
    }
    catch(error){
      console.log("error in recruiter description edit");
    }
    
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="d-flex flex-row justify-content-between align-content-center">
          <h1 className="profile--headers">About</h1>
          <a className="profile--link" onClick={()=>{setEditStatus(!editStatus); 
            editPostCall()
          }}>
            {editStatus? 'Save':'Edit'}
          </a>
        </div>
      </div>
      <div className="row">
        
          {editStatus? 
            (<textarea className="profile--body" value={profileAbout} onChange={(e)=>{handleProfileChange(e)}}/>):
            (<p className="profile--body">{profileAbout} </p>)
          }
          
      
      </div>
    </div>
  );
};

export default RecruiterAbout;
