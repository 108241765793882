import React, { useState, useRef, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import $ from "jquery";
import "./style.css";

function EditPositionPopup({ position, onClose, update_refresh }) {
  const [jobTitle, setJobTitle] = useState(position.position || "");
  const [locationForm, setLocationForm] = useState(position.positionLocation || "");
  const [jobType, setJobType] = useState(position.positionType || "Full-Time");
  const [description, setDescription] = useState(position.jobDescription || "");
  const descRef = useRef(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [originalValues] = useState({
    jobTitle: position.position || "",
    locationForm: position.positionLocation || "",
    jobType: position.positionType || "Full-Time",
    description: position.jobDescription || ""
    });

  const editInterviewEndpoint = process.env.REACT_APP_UPDATE_COMPANY_INTERVIEW;

  const checkForChanges = (newValues) => {
    const hasChanged = 
      newValues.jobTitle !== originalValues.jobTitle ||
      newValues.locationForm !== originalValues.locationForm ||
      newValues.jobType !== originalValues.jobType ||
      newValues.description !== originalValues.description;
    
    setHasChanges(hasChanged);
  };
  
  const handleTitleChange = (e) => {
    const newValue = e.target.value;
    setJobTitle(newValue);
    checkForChanges({
      jobTitle: newValue,
      locationForm,
      jobType,
      description
    });
  };
  
  const handleLocationChange = (e) => {
    const newValue = e.target.value;
    setLocationForm(newValue);
    checkForChanges({
      jobTitle,
      locationForm: newValue,
      jobType,
      description
    });
  };
  
  const handleTypeChange = (e) => {
    const newValue = e.target.value;
    setJobType(newValue);
    checkForChanges({
      jobTitle,
      locationForm,
      jobType: newValue,
      description
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const payload = {
      CompanyInterviewId: position.companyInterviewId,
      JobDescription: description,
      Position: jobTitle,
      PositionType: jobType,
      PositionLocation: locationForm,
    };
      
    $.ajax({
      url: editInterviewEndpoint,
      method: 'PUT',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: JSON.stringify(payload),
      success: (res) => {
        if (res.status === "Success") {
          alert("Job posting updated successfully!");
          update_refresh();
          onClose();
        } else {
          console.error("API Error:", res);
          alert("Failed to update job posting. Please try again.");
        }
      },
      error: (err) => {
        console.error("Error occurred while sending API request:", err);
        alert("An error occurred. Please try again later.");
      }
    });
  };

  const RequiredSpan = styled(Typography)(() => ({
    fontFamily: "Montserrat, san-serif !important",
    color: "#FF0000",
    display: "inline",
    fontSize: "20px !important",
    marginLeft: "3px",
  }));

  useEffect(() => {
    setIsFormValid(
      jobTitle.trim().length > 0 &&
      locationForm.trim().length > 0 &&
      jobType.trim().length > 0 &&
      description.trim().length > 0
    );
  }, [jobTitle, locationForm, jobType, description]);

  return (
    <div className="edit-position-overlay">
      <div className="edit-position-container">
        <button className="edit-position-close" onClick={onClose}>&times;</button>
        <h2 className="edit-position-header">Edit Job Posting</h2>
        
        <form className="edit-position-form" onSubmit={handleSubmit}>
          <div className="edit-position-form-group">
            <label className="edit-position-label">
              Job Title
              <RequiredSpan>*</RequiredSpan>
            </label>
            <input
              className="edit-position-input"
              type="text"
              value={jobTitle}
              onChange={handleTitleChange}
              required
            />
          </div>

          <div className="edit-position-form-inline">
            <div className="edit-position-form-group">
              <label className="edit-position-label">
                Location
                <RequiredSpan>*</RequiredSpan>
              </label>
              <input
                className="edit-position-input"
                type="text"
                value={locationForm}
                onChange={handleLocationChange}
                required
              />
            </div>

            <div className="edit-position-form-group">
              <label className="edit-position-label">
                Type
                <RequiredSpan>*</RequiredSpan>
              </label>
              <select
                className="edit-position-input"
                value={jobType}
                onChange={handleTypeChange}
              >
                <option value="Full-Time">Full-Time</option>
                <option value="Part-Time">Part-Time</option>
                <option value="Intern">Intern</option>
                <option value="Contracter">Contracter</option>
              </select>
            </div>
          </div>

          <div className="edit-position-form-group">
            <label className="edit-position-label">
              Description
              <RequiredSpan>*</RequiredSpan>
            </label>
            <Editor
                apiKey="7wf0mtg4eoef8ulv8xginyjk23eyv5e9a0midfl7vqmevm26"
                onInit={(evt, editor) => {
                    descRef.current = editor;
                    editor.setContent(description);  // Set initial content here
                }}
                value={description}  // Use value instead of initialValue
                onEditorChange={(content) => {
                    setDescription(content);
                    checkForChanges({
                    jobTitle,
                    locationForm,
                    jobType,
                    description: content
                    });
                }}
                init={{
                    height: 300,
                    menubar: false,
                    plugins: [
                    "advlist", "autolink", "lists", "link", "charmap", "preview",
                    "anchor", "searchreplace", "visualblocks", "code", "fullscreen",
                    "insertdatetime", "table", "code", "help", "wordcount"
                    ],
                    toolbar:
                    "undo redo | blocks | bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                    content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }"
                }}
            />
          </div>

          <div className="edit-position-form-button">
            <button 
                className="edit-position-button" 
                type="submit" 
                disabled={!isFormValid || !hasChanges}
                style={{
                    backgroundColor: (!isFormValid || !hasChanges) ? "#cccccc" : "#1daf5c",
                    cursor: (!isFormValid || !hasChanges) ? "not-allowed" : "pointer",
                    opacity: (!isFormValid || !hasChanges) ? 0.7 : 1
                }}
                >
                Update Job Posting
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditPositionPopup;