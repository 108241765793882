import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import "../RejectPopup/style.css";

function HiredPopup({
    isPopupVisible,
    onClose,
    candidateToInterviewId,
    candidateEmail,
    candidateName,
    position,
    companyName,
}) {
    const [subject, setSubject] = React.useState("");
    const [content, setContent] = React.useState("");

    const sendHiredEmailEndpoint = process.env.REACT_APP_SEND_HIRED_EMAIL;

    React.useEffect(() => {
        // Set default subject and content whenever the popup becomes visible
        if (isPopupVisible) {
            setSubject(`${companyName} - ${position} Application Update`);
            setContent(
                `Dear ${candidateName},\n\nWe are pleased to inform you that we would like to move forward with your application for the ${position} position at ${companyName}. We were impressed by your background and qualifications and are excited about the opportunity to work with you.\n\nOur team will be in touch shortly with the next steps in the process. Please don't hesitate to reach out if you have any questions in the meantime.\n\nThank you for your interest, and we look forward to connecting with you soon!`            );
        }
    }, [isPopupVisible, companyName, position, candidateName]);

    const handleSendEmail = async () => {
        if (!subject || !content) {
            alert("Both Subject and Content are required.");
            return;
        }

        try {
            const response = await fetch(sendHiredEmailEndpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    candidateToInterviewId,
                    candidateEmail,
                    subject,
                    content,
                }),
            });

            if (!response.ok) {
                throw new Error("Failed to send email");
            }

            alert("Hired email sent successfully!");
            onClose(); // Close the popup after successful email sending
            window.location.reload();
        } catch (error) {
            console.error("Error sending hired email:", error);
            alert("Failed to send email. Please try again.");
        }
    };

    if (!isPopupVisible) return null;

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <button
                    className="close-popup-button"
                    onClick={onClose}
                >
                    &times;
                </button>
                <h2>Hired Email</h2>

                <label htmlFor="subject">Subject Line *</label>
                <input
                    type="text"
                    id="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    style={{
                        marginBottom: "10px",
                        padding: "10px",
                        width: "100%",
                    }}
                />

                <label htmlFor="content">Content *</label>
                <Editor
                    apiKey="7wf0mtg4eoef8ulv8xginyjk23eyv5e9a0midfl7vqmevm26"
                    value={content}
                    onEditorChange={(content) => setContent(content)}
                    init={{
                        height: 250,
                        menubar: false,
                        plugins: [
                            "advlist",
                            "autolink",
                            "lists",
                            "link",
                            "charmap",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "code",
                            "fullscreen",
                            "insertdatetime",
                            "table",
                            "code",
                            "help",
                            "wordcount",
                        ],
                        toolbar:
                            "undo redo | blocks | bold italic forecolor | " +
                            "alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help",
                        content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                />

                <button
                    className="send-email-button"
                    onClick={handleSendEmail}
                >
                    Send
                </button>
            </div>
        </div>
    );
}

export default HiredPopup;