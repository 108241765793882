import React from 'react';
import styles from './Loader.module.css';
import Logo from '../../../images/logo-circle.svg';

const Loader = () => {
  return (
    <div className={styles.loaderContainer}>
      <img src={Logo} className={styles.logo} alt="Intern Guys" />
      <div className={styles.dots}>
        <span>.</span><span>.</span><span>.</span>
      </div>
    </div>
  );
};

export default Loader;

