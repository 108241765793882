import React, { useEffect, useState } from 'react'
import Alert from '@mui/material/Alert';
import { Collapse } from '@mui/material';
import $ from "jquery";
import "./styles.css";
import { InlineWidget } from 'react-calendly';
import NavigationBar from '../../components/navigation';
import Footer from '../../components/footer';

const Contact = () => {

    const [displaySubmittedMsg, setDisplaySubmittedMsg] = useState(false)
    const [submitBtnMsg, setSubmitBtnMsg] = useState("Submit")
    const [submitBtnColor, setSubmitBtnColor] = useState("#035DA9")

    useEffect(() => {
        setTimeout(() => {
            setDisplaySubmittedMsg(false)
            setSubmitBtnMsg("Submit")
            setSubmitBtnColor("#035DA9")
        }, 3000)
    }, [displaySubmittedMsg])

    const [formContents, setFormContents] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
    });
    
    const sending = () => {
        $.ajax({
            type: "POST",
            contentType: "application/json; charset=utf-8",
            url: `${process.env.REACT_APP_SEND_CONTACT_ENDPOINT}`,
            data: JSON.stringify(formContents),
        });
    };
    
    function handleOnChange(e) {
        if (e.target.name === "name") {
            setFormContents({ ...formContents, name: e.target.value });
        }
        if (e.target.name === "email") {
            setFormContents({ ...formContents, email: e.target.value });
        }
        if (e.target.name === "subject") {
            setFormContents({ ...formContents, subject: e.target.value });
        }
        if (e.target.name === "message") {
            setFormContents({ ...formContents, message: e.target.value });
        }
    }
    
    function dropdownValidate() {
        const dropdown = document.querySelector("#contact-form__dropdown");
        const content = dropdown.innerHTML.trim();
        if (content === "Subject") {
            dropdown.classList.add("is-invalid");
            dropdown.classList.remove("is-valid");
            return false;
        } else {
            dropdown.classList.remove("is-invalid");
            dropdown.classList.add("is-valid");
            return true;
        }
    }
    
    function formValidate(event) {
        "use strict";
        const form = document.querySelector(".needs-validation");
        const dropdownValidated = dropdownValidate();
        if (!form.checkValidity() || !dropdownValidated) {
            event.preventDefault();
            event.stopPropagation();
            form.classList.add("was-validated");
            return false;
        }
        return true;
    }

    function onFormSubmit(e) {
        const validated = formValidate(e);
        if (!validated) {
            e.preventDefault();
        } else {
            e.preventDefault();

            setDisplaySubmittedMsg(true)
            setSubmitBtnMsg("Submitting...")
            setSubmitBtnColor("#213546")
            sending();
        }
    }
    
  return (
    <div>
        <NavigationBar />
        <Collapse in={displaySubmittedMsg} className='submitted-msg'>
            <Alert 
                severity="success">
                Thank you for sending us a message! We will get back to you at our
                earliest convenience!
            </Alert>
        </Collapse>
        <div className='contact-form-title'>
            <h2>Contact Us</h2>
            <h4>For more info, fill out the form or email us at contact@internguys.com</h4>
        </div>
        <div className="contact-form">
            <form onSubmit={onFormSubmit} className="needs-validation" noValidate>
                <div className="row">
                    <div className="col-12 col-sm-4 ps-0">
                    <div className="input-group input-group-lg mb-4">
                        <input
                        type="text"
                        name="name"
                        onChange={(e) => handleOnChange(e)}
                        className="form-control contact-form__input"
                        placeholder="Name"
                        aria-label="Name"
                        required
                        />
                        <div className="invalid-feedback">Name cannot be blank</div>
                    </div>
                    </div>
                    <div className="col-12 col-sm-8 ps-0">
                    <div className="input-group input-group-lg mb-4">
                        <input
                        type="text"
                        name="email"
                        onChange={(e) => handleOnChange(e)}
                        className="form-control contact-form__input"
                        placeholder="Email"
                        aria-label="Email"
                        required
                        />
                        <div className="invalid-feedback">Email cannot be blank</div>
                    </div>
                    </div>
                    <div className="col-12 ps-0">
                    <div className="input-group btn-group d-grid mb-4">
                        <button
                        className="btn contact-form__dropdown-btn btn-lg dropdown-toggle d-flex justify-content-between align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        id="contact-form__dropdown"
                        preventDefault
                        required
                        >
                        {formContents.subject.length === 0
                            ? "Subject"
                            : formContents.subject}
                        </button>
                        <div className="invalid-feedback">Subject cannot be blank</div>
                        <ul className="dropdown-menu  w-100">
                        <li>
                            <button
                            className="dropdown-item"
                            type="button"
                            href="#"
                            name="subject"
                            value="General Inquiry"
                            preventDefault
                            onClick={(e) => handleOnChange(e)}
                            >
                            General Inquiry
                            </button>
                        </li>
                        <li>
                            <button
                            className="dropdown-item"
                            type="button"
                            href="#"
                            name="subject"
                            value="Recruiters"
                            preventDefault
                            onClick={(e) => handleOnChange(e)}
                            >
                            Recruiters
                            </button>
                        </li>
                        <li>
                            <button
                            className="dropdown-item"
                            type="button"
                            href="#"
                            name="subject"
                            value="Students"
                            preventDefault
                            onClick={(e) => handleOnChange(e)}
                            >
                            Students
                            </button>
                        </li>
                        </ul>
                    </div>
                    </div>
                    <div className="col-12 ps-0">
                    <div className="form-group">
                        <textarea
                        className="form-control contact-form__text-area"
                        name="message"
                        onChange={(e) => handleOnChange(e)}
                        id="exampleFormControlTextarea1"
                        rows="7"
                        placeholder="Type your message here..."
                        required
                        ></textarea>
                        <div className="invalid-feedback">Message cannot be blank</div>
                    </div>
                    </div>
                </div>
                <div className="col-12 d-flex contact-form__btn-div">
                    <button 
                        id="contact-form__btn" 
                        type="submit" 
                        className="mt-4"
                        color={submitBtnColor}
                        >
                        {submitBtnMsg}
                    </button>
                </div>
            </form>
        </div>

        <div className='schedule-title'>
            <h2>Chat with a team member</h2>
        </div>

        <div className='schedule-form'>
            <div className='schedule-description'>
                <p>If you would like to further understand our services, feel free to schedule a call here. We would be happy to answer any questions
                    you have about our products, discuss use cases, or get pricing information.
                </p>
            </div>
            <div className='calendly_inner_container'>
                <InlineWidget
                    styles={{
                        position:'absolute',
                        top: '0',
                        bottom: '0',
                        width: '100%'
                    }}
                    pageSettings={{
                        backgroundColor: 'ffffff',
                        hideEventTypeDetails: false,
                        hideLandingPageDetails: false,
                        primaryColor: '00a2ff',
                        textColor: '4d5055'
                    }}
                    url="https://calendly.com/internguys/intern-guys-demo-questionnaire"
                />
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default Contact;