import React, { useState } from "react";
import Arrow from "../../images/how-it/arrow-down.png"
import "./styles.css";

export default function FAQBox({ question, answer, isTop, isBottom }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`faq-box ${isTop ? "top" : ""} ${isBottom ? "bottom" : ""}` }
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="faq-title" >
        {question}
        <span className={`arrow ${isOpen ? "open" : ""}`}><img className="img-arrow" src={Arrow}/></span>
      </div>
      <div className={`faq-content ${isOpen ? "open" : "close"}`}>{answer}</div>
    </div>
  );
}
