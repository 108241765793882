import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../navbar";
import "./style.css"; 
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Editor } from "@tinymce/tinymce-react";
import $ from "jquery";

function PostPosition() {
  const navigate = useNavigate();
  const [jobTitle, setJobTitle] = useState("");
  const [locationForm, setLocationForm] = useState("");
  const [jobType, setJobType] = useState("Full-Time");
  const [description, setDescription] = useState("");
  const [jobLink, setJobLink] = useState("");
  const descRef = useRef(null);
  const [isFormValid, setIsFormValid] = useState(false);

  const createInterviewEndpoint = process.env.REACT_APP_CREATE_COMPANY_INTERVIEW;

  const companyId = localStorage.getItem("companyId");

  const logDescription = () => {
    if (descRef.current) {
      setDescription(descRef.current.getContent());
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const payload = {
      CompanyId: companyId,
      JobDescription: description,
      Position: jobTitle,
      PositionType: jobType,
      PositionLocation: locationForm,
    };
      
    $.ajaxSetup({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  
    $.post(
      `${createInterviewEndpoint}`,
      JSON.stringify(payload),
      (res) => {
        console.log("API Response:", res);
  
        if (res.status === "Success") {
          alert("Job posting created successfully!");

          console.log("jobLink: ", res.jobLink);
          setJobLink(res.jobLink);
                    
          // Navigate to dashboard
          navigate("/dashboard", {
            state: {
              jobTitle: jobTitle,
              jobLink: res.jobLink,
              isPopupOpen: true,
            },
          });
        } else {
          console.error("API Error:", res);
          alert("Failed to create job posting. Please try again.");
        }
      }
    ).fail((err) => {
      console.error("Error occurred while sending API request:", err);
      alert("An error occurred. Please try again later.");
    });
  };

  const RequiredSpan = styled(Typography)(() => ({
    fontFamily: "Montserrat, san-serif !important",
    color: "#FF0000",
    display: "inline",
    fontSize: "20px !important",
    marginLeft: "3px",
  }));

  // Form validation logic
  useEffect(() => {
    setIsFormValid(
      jobTitle.trim().length > 0 &&
      locationForm.trim().length > 0 &&
      jobType.trim().length > 0 &&
      description.trim().length > 0
    );
  }, [jobTitle, locationForm, jobType, description]);

  return (
    <>
    <Navbar showSidebar={false} />
    <div className="post-position-container">
          <div className="post-position-header">
              Create Your Job Posting
          </div>
          <section className="post-position-section">
              <form className="post-position-form" onSubmit={handleSubmit}>
                  <div className="post-position-form-group">
                      <label className="post-position-label">
                          Job Title
                          <RequiredSpan>*</RequiredSpan>
                      </label>
                      <input
                          className="post-position-input"
                          type="text"
                          placeholder="e.g. Graphic Designer"
                          value={jobTitle}
                          onChange={(e) => setJobTitle(e.target.value)}
                          required />
                  </div>
                  <div className="post-position-form-inline">
                      <div className="post-position-form-group">
                          <label className="post-position-label">
                              Location
                              <RequiredSpan>*</RequiredSpan>
                          </label>
                          <input
                              className="post-position-input"
                              type="text"
                              placeholder="e.g. New York or Remote"
                              value={locationForm}
                              onChange={(e) => setLocationForm(e.target.value)}
                              required />
                      </div>

                      <div className="post-position-form-group">
                          <label className="post-position-label">
                              Type
                              <RequiredSpan>*</RequiredSpan>
                          </label>
                          <select
                              className="post-position-input"
                              value={jobType}
                              onChange={(e) => setJobType(e.target.value)}
                          >
                              <option value="Full-Time">Full-Time</option>
                              <option value="Part-Time">Part-Time</option>
                              <option value="Intern">Intern</option>
                              <option value="Contracter">Contracter</option>
                          </select>
                      </div>
                  </div>

                  <div className="post-position-form-group">
                    <label className="post-position-label">
                        Description
                    <RequiredSpan>*</RequiredSpan>
                    </label>

                    <Editor
                        apiKey="7wf0mtg4eoef8ulv8xginyjk23eyv5e9a0midfl7vqmevm26"
                        onInit={(evt, editor) => (descRef.current = editor)}
                        value={description}
                        onEditorChange={() => logDescription()}
                        init={{
                          placeholder: `1) Responsibilities - List key responsibilities of the role.
                          
                          2) Requirements - Specify essential qualifications/experience (e.g. 5+ years of experience).
                      
                          3) Candidate Must Have - State mandatory conditions such as start date, location, hours, and salary.
                      
                          4) Who We Are - Brief description of your company.`,
                        height: 450,
                        menubar: false,
                        plugins: [
                            "advlist",
                            "autolink",
                            "lists",
                            "link",
                            "charmap",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "code",
                            "fullscreen",
                            "insertdatetime",
                            "table",
                            "code",
                            "help",
                            "wordcount",
                        ],
                        toolbar:
                            "undo redo | blocks | " +
                            "bold italic forecolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help",
                        content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                    />

                  </div>
                  <div className="post-position-form-button">
                      <button className="post-position-button" type="submit" disabled={!isFormValid}
                      style={{
                        backgroundColor: isFormValid ? "#1daf5c" : "#cccccc",
                        cursor: isFormValid ? "pointer" : "not-allowed",
                      }}>
                          Add Job Posting
                      </button>
                  </div>
              </form>
          </section>
      </div></>
  );
}

export default PostPosition;