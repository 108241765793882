import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import './styles.css';
import Avatar from '@mui/material/Avatar';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import backArrow from '../../images/backArrow.svg';
import closeButton from '../../images/closeButton.svg';
import email from '../../images/email.svg';
import telephone from '../../images/telephone.svg';
import location from '../../images/location.svg';
import download from '../../images/download.svg';
import view from '../../images/view.svg';


const style = {
    position: 'absolute',
    width: '55vw',
    height: '65vh',
    top: '20%',
    left: '20%',
    bgcolor: 'background.paper',
    p: 4,
    fontFamily: 'Montserrat, san-serif !important',
  };

export default function ApplicantList() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate();

    // dummy data for applicant
    const [intern, setIntern] = useState({
        name: '',
        email: '',
        telephone: '',
        location: '',
        status: '',
    });

    const [viewIntern, setViewIntern] = useState(false);

    // instead of this list, we will iterate through data from database using get request.
    // The list is used as dummy data to determine placement of elements and style of the page
    const [list, setList] = useState({
        0: {
            name: 'Angel Mason',
        },
        1: {
            name: 'Michael Torp'
        },
        2: {
            name: 'Kelly Klaws'
        },
        3: {
            name: 'Crystal Kim'
        },
        4: {
            name: 'John Smith',
            email: 'internguys@gmail.com',
            telephone: '+1 (234) 567-8910',
            location: 'Irvine, CA',
            status: 'Pending',
        },
    })

    function chooseIntern(id) {
        console.log(list[id]);
        setIntern((prevIntern) => ({
            ...prevIntern, 
            name: list[id].name,
            email: list[id].email,
            telephone: list[id].telephone,
            location: list[id].location,
            status: list[id].status
        }));
        setViewIntern(true);
        handleOpen();
    }

    function goDashBoard() {
        navigate('/dashboard');
    }

    return (
        <div>
            <div className="search-back-button-wrapper">
                <button className="search-back-button" onClick={goDashBoard}><img src={backArrow}/>Back</button>
            </div>
            <div className="grid-container">
                {Object.keys(list).map(([key, index]) => {
                    return (
                    <div className="grid-item">
                        <div className="intern-info">
                        {list[key].picture ? <Avatar sx={{
                                backgroundSize: 'cover',
                                width: '10vh',
                                minHeight: '10vh',
                                color: 'rgb(158, 156, 156)',
                                background: 'rgb(235, 235, 235)',
                                borderRadius: '60%',
                                marginLeft: '1rem',
                        }} src="link" /> : 
                        <Avatar sx={{
                            backgroundSize: 'cover',
                            width: '10vh',
                            minHeight: '10vh',
                            color: 'rgb(158, 156, 156)',
                            background: 'rgb(235, 235, 235)',
                            borderRadius: '60%',
                            marginLeft: '1rem',
                        }} src="/broken-image.jpg" />
                        }
                            <h1>{list[key].name}</h1>
                        </div>
                        <div className="button-wrapper"> 
                            <button type="button" className="edit-button">Edit Status</button>
                            <button id={key} type="button" className="view-button" onClick={(e) => chooseIntern(e.currentTarget.id)}>View</button>
                        </div>
                    </div>
                    )
                })}

                {intern && (
                    <div >
                        <Modal className="modal"
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Box style={{ display: 'flex', flexDirection: "row", justifyContent: "space-between" }}>
                                <div className="modal-button-wrapper">
                                    <Button className="modal-button" onClick={handleClose}><img src={closeButton} alt="" /></Button>
                                </div>
                                </Box>
                                <div className="applicant-card">
                                    <div className="left-side-card">
                                    <Avatar sx={{
                                        backgroundSize: 'cover',
                                        width: '30vh',
                                        minHeight: '30vh',
                                        color: 'rgb(158, 156, 156)',
                                        background: 'rgb(235, 235, 235)',
                                        borderRadius: '60%',
                                        marginLeft: '1rem',
                                    }} src="link" />
                                    <select defaultValue={'DEFAULT'}>
                                        <option value="DEFAULT" disabled hidden>{intern.status}</option>
                                    </select>
                                    </div>
                                    <div className="right-side-card">
                                        <div className="right-intro">
                                        <h1>{intern.name}</h1>
                                        <h2>Contact Information</h2>
                                        <p className="contact-method">contact applicant via email or phone for next steps</p>
                                        </div>
                                        <div className="right-contact">
                                            <div className="right-contact-email">
                                                <img src={email} />
                                                <a href="#" alt="email">{intern.email ? intern.email : 'N/A'}</a>
                                            </div>
                                            <div className="right-contact-telephone">
                                            <img src={telephone} />
                                                <p>{intern.telephone ? intern.telephone : 'N/A'}</p>
                                            </div>
                                            <div className="right-contact-location">
                                                <img src={location} />
                                                <p>{intern.location ? intern.location : 'N/A'}</p>
                                            </div>
                                        </div>
                                        <div className="resume-container">
                                            <p>Resume.pdf</p>
                                            <div></div>
                                            <button type="button"><img src={download} className="resume-download" /></button>
                                            <button type="button"><img src={view} className="resume-view" /></button>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Modal>
                    </div>
                )}
            </div>
        </div>
   
    )
}