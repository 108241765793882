import React, { useState, useEffect } from "react";
import "../../Profile.css";
import linkedInIcon from "../../../../images/linkedin-black.svg"
import emailIcon from "../../../../images/email.svg"
import phoneIcon from "../../../../images/telephone.svg"
import locationIcon from "../../../../images/location.svg"


const RecruiterContactInformation = ({editPost, recruiterEmail, phoneNumber, companyLocation, companyLinkedin}) => {
  const [editStatus,setEditStatus]=useState(false);
  const [emailInfo,setEmailInfo]=useState("internguys@gmail.com");
  const [phoneInfo,setPhoneInfo]=useState("(234) 567-8910");
  const [locationInfo,setLocationInfo]=useState("Irvine, CA");
  const [linkedinInfo,setLinkedinInfo]=useState("linkedin");

  const handleEmailChange = (e)=>{
    setEmailInfo(e.target.value);
  }
  const handlePhoneChange = (e)=>{
    setPhoneInfo(e.target.value);
  }
  const handleLocationChange = (e)=>{
    setLocationInfo(e.target.value);
  }
  const handleLinkedinChange = (e)=>{
    setLinkedinInfo(e.target.value);
  }
  useEffect(()=>{
    if(recruiterEmail!==null){
      setEmailInfo(recruiterEmail);
    }
    if(phoneNumber!==null){
      setPhoneInfo(phoneNumber);
    }
    if(companyLocation!==null){
      setLocationInfo(companyLocation);
    }
    if(companyLinkedin!==null){
      setLinkedinInfo(companyLinkedin);
    }
  },[recruiterEmail,phoneNumber,companyLocation,companyLinkedin]);
  const editPostCall=()=>{
    if(editStatus==false){
      return;
    }
    try{
      editPost({recruiterEmail:emailInfo, recruiterPhone:phoneInfo, companyHeadquarters:locationInfo,linkedIn:linkedinInfo});
    }
    catch(error){
      console.log("error in recruiter contact edit");
    }
    
  }
  return (
    <div className="container-fluid">
      <div className="row">
            <div className="d-flex flex-row justify-content-between align-content-center">
              <h1 className="profile--headers">Contact Information</h1>
              <a className="profile--link" onClick={()=>{setEditStatus(!editStatus); editPostCall()}}>
                {editStatus? 'Save':'Edit'}
              </a>
            </div>
          </div>
          <div className="row profile--body">
            <div className="d-flex flex-column align-items-start">
              <span className="d-flex flex-row profile__contact-info">
              <img src={emailIcon} alt="" className="profile__svg"/>

                {editStatus ? (<textarea value={emailInfo} onChange={(e)=>{handleEmailChange(e)}}/>): (<>&nbsp; &nbsp; &nbsp; {emailInfo}</>)}
              </span>
              <span className="d-flex flex-row profile__contact-info">
              <img src={phoneIcon} alt="" className="profile__svg"/>
              {editStatus ? (<textarea value={phoneInfo} onChange={(e)=>{handlePhoneChange(e)}}/>): (<>&nbsp; &nbsp; &nbsp; {phoneInfo}</>)}
              </span>
              <span className="d-flex flex-row profile__contact-info">
              <img src={locationIcon} alt="" className="profile__svg"/>
              {editStatus ? (<textarea value={locationInfo} onChange={(e)=>{handleLocationChange(e)}}/>): (<>&nbsp; &nbsp; &nbsp; {locationInfo}</>)}
              </span>

              <span className="d-flex flex-row profile__contact-info">
              <img src={linkedInIcon} alt="" className="profile__svg"/>
              {editStatus ? (<textarea value={linkedinInfo} onChange={(e)=>{handleLinkedinChange(e)}}/>): (<>&nbsp; &nbsp; &nbsp; {linkedinInfo}</>)}
              </span>
            </div>
          </div>
    </div>
  );
};

export default RecruiterContactInformation;