import React from "react";
import "../../Profile.css";
import profilePic from "../company-pic.png";

const CompanyProfileUser = (props) => {
  return (
    <div>
    <div className="d-flex flex-row">
      <div className="company-profile-user__profile-img-div">
        <img className="company-profile-user__profile-img" alt="profile picture" src={profilePic} />
      </div>
      <div className="company-profile-name-div">
          {props.companyName!==null?(<h1 className="company-profile-name">{props.companyName}</h1>):(<h1 className="company-profile-name">Intern Guys</h1>)}
          <div className="d-flex flex-row mt-5 profile-user__btn-group">
            <button onClick={props.onRecruiterBtnClicked} className="profile-user__btn me-3">Recruiter</button>
            <button className="profile-user__btn--chosen ms-3">Company</button>
          </div>
      </div>
    </div>
    </div>
  );
};

export default CompanyProfileUser;
