import React from 'react'
import styles from './Suggestions.module.css';

const SuggestionButton = ({children, sendMessage}) => {
  return (
    <button className={styles.button} onClick={() => sendMessage(children)}>{children}</button>
  )
}


const Suggestions = ({sendMessage}) => {
  return (
    <div className={styles.container}>
      <h4>Here are Some Suggestions</h4>
      <SuggestionButton sendMessage={sendMessage}>I’m looking for marketing interns</SuggestionButton>
      <SuggestionButton sendMessage={sendMessage}>Which positions can Intern Guys help me with</SuggestionButton>
      <SuggestionButton sendMessage={sendMessage}>How does Intern Guys work</SuggestionButton>
    </div>
  )
}

export default Suggestions;
