import React, {useEffect, useState} from "react";
import "../../Profile.css";
import locationIcon from "../../../../images/location.svg"
import industryIcon from "../../../../images/industry.svg"
import peopleIcon from "../../../../images/people.svg"
import birthdayIcon from "../../../../images/birthday.svg"


const CompanyOverview = ({editPost, companySize, companyField, dateFounded, companyLocation}) => {
  const [editStatus,setEditStatus]=useState(false);
  const [sizeInfo,setSizeInfo]=useState("1-10");
  const [fieldInfo,setFieldInfo]=useState("N/A");
  const [dateInfo,setDateInfo]=useState("2 years");
  const [locationInfo,setLocationInfo]=useState("Irvine, CA");
  const handleSizeChange = (e)=>{
    setSizeInfo(e.target.value);
  }
  const handleFieldChange = (e)=>{
    setFieldInfo(e.target.value);
  }
  const handleDateChange = (e)=>{
    setDateInfo(e.target.value);
  }
  const handleLocationChange = (e)=>{
    setLocationInfo(e.target.value);
  }

  useEffect(()=>{
    if(companySize!==null){
      setSizeInfo(companySize);
    }
    if(companyField!==null){
      setFieldInfo(companyField);
    }
    if(dateFounded!==null){
      setDateInfo(dateFounded);
    }
    if(companyLocation!==null){
      setLocationInfo(companyLocation);
    }
  },[companySize,companyField,dateFounded,companyLocation]);
  
  const editPostCall=()=>{
    if(editStatus==false){
      return;
    }
    try{
      editPost({companyHeadquarters:locationInfo,companyField:fieldInfo,companySize:sizeInfo,dateFounded:dateInfo});
    }
    catch(error){
      console.log(error,"edit error in company overview");
    }
    
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="d-flex flex-row justify-content-between align-content-center">
          <h1 className="profile--headers">Overview</h1>
          <a className="profile--link" onClick={()=>{setEditStatus(!editStatus); editPostCall()}}>
            {editStatus? 'Save':'Edit'}
          </a>
        </div>
      </div>
      <div className="row profile--body">
        <div className="d-flex flex-column align-items-start">
          <span className="d-flex flex-row profile__contact-info">
          <img src={locationIcon} alt="" className="profile__svg"/>
            {editStatus ? (<textarea value={locationInfo} onChange={(e)=>{handleLocationChange(e)}}/>): (<>&nbsp; &nbsp; &nbsp; {locationInfo}</>)}
          </span>
          <span className="d-flex flex-row profile__contact-info">
          <img src={industryIcon} alt="" className="profile__svg"/>
            {editStatus ? (<textarea value={fieldInfo} onChange={(e)=>{handleFieldChange(e)}}/>): (<>&nbsp; &nbsp; &nbsp; {fieldInfo}</>)}
          </span>
          <span className="d-flex flex-row profile__contact-info">
          <img src={peopleIcon} alt="" className="profile__svg"/>
            {editStatus ? (<textarea value={sizeInfo} onChange={(e)=>{handleSizeChange(e)}}/>): (<>&nbsp; &nbsp; &nbsp; {sizeInfo}</>)}
 
          </span>

          <span className="d-flex flex-row profile__contact-info">
          <img src={birthdayIcon} alt="" className="profile__svg"/>

            {editStatus ? (<textarea value={dateInfo} onChange={(e)=>{handleDateChange(e)}}/>): (<>&nbsp; &nbsp; &nbsp; {dateInfo}</>)} 

            
          </span>
        </div>
      </div>
    </div>
  );
};

export default CompanyOverview;
