import React, { useState } from "react";

function usePagination(items, pageLimit) {
  const [pageNumber, setPageNumber] = useState(1);
  const pageCount = Math.ceil(items.length / pageLimit);

  const changePage = (pN) => {
    setPageNumber(pN);
  };

  const pageData = () => {
    const s = (pageNumber - 1) * pageLimit; // Adjust to start from 0 for slicing
    const e = s + pageLimit;
    return items.slice(s, e);
  };

  const nextPage = () => {
    setPageNumber(Math.min(pageNumber + 1, pageCount));
  };

  const previousPage = () => {
    setPageNumber(Math.max(pageNumber - 1, 1)); // Ensure it doesn't go below 1
  };

  return {
    pageNumber,
    pageCount,
    changePage,
    pageData,
    nextPage,
    previousPage,
  };
}

export default usePagination;