import React, { useState } from 'react';
import Navbar from '../../components/navbar'
import ApplicantList from '../../components/applicantList'

function search() {
    const [search, setSearch] = useState(true);

    return (
        <div>
            <Navbar search={search}/>
            <ApplicantList />
        </div>
    )
}

export default search;