import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import "../RejectPopup/style.css";

function InterviewWNoraPopup({
    isPopupVisible,
    onClose,
    candidateToInterviewId,
    candidateEmail,
    candidateName,
    interviewWithNora,
    position,
    companyName,
    interviewURL,
    setLoadingApplicantId, 
    setApplicants,         
    setEmailSentApplicantId,
}) {
    const [subject, setSubject] = React.useState("");
    const [content, setContent] = React.useState("");
    const [copySuccess, setCopySuccess] = useState(false);

    const sendCandidateInterviewEndpoint = process.env.REACT_APP_SEND_CANDIDATE_INTERVIEW;

    const copyToClipboard = () => {
        navigator.clipboard.writeText(interviewURL);
        setCopySuccess(true);
    
        // Reset copy success after 2 seconds
        setTimeout(() => setCopySuccess(false), 2000);
    };

    React.useEffect(() => {
        // Set default subject and content whenever the popup becomes visible
        if (isPopupVisible) {
            setSubject(`${position} - Interview Next Steps`);

            setContent(
                `Hey ${candidateName},<br><br>We're pleased to inform you that ${companyName} would like to move forward with your application for our ${position} position!<br><br>As part of the next step, we're inviting you to complete an interview powered by Nora AI from Intern Guys!<br><br>Interview Link:<br><br>You can complete the interview at any time that's convenient for you. Simply click the link below to get started:<br><br>${interviewURL}<br><br>We encourage you to do this as soon as possible, so we can continue with the next steps promptly.<br><br>If you have any questions or run into any issues, don't hesitate to reach out.<br><br>Team ${companyName}`
            );
        }
    }, [isPopupVisible, companyName, position, candidateName]);

    const handleSendEmail = async () => {
        if (interviewWithNora) {
            // If already true, do nothing
            return;
        }

        if (!subject || !content) {
            alert("Both Subject and Content are required.");
            return;
        }
    
        setLoadingApplicantId(candidateToInterviewId); // Start the loading indicator
    
        try {
            const payload = {
                candidateToInterviewId,
                candidateName,
                candidateEmail,
                companyName,
                position,
                interviewUrl: interviewURL, 
            };
    
            const response = await fetch(sendCandidateInterviewEndpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) {
                throw new Error("Failed to send email and update interview status.");
            }

            if (response.ok) {
                setLoadingApplicantId(null);
                setEmailSentApplicantId(candidateToInterviewId);
              }
        
            // Update the local state to reflect the change
            setApplicants((prevApplicants) =>
                prevApplicants.map((applicant) =>
                    applicant.candidateToInterviewId === candidateToInterviewId
                        ? { ...applicant, interviewWithNora: true }
                        : applicant
                )
            );
        
            // Clear the email sent indicator after a delay
            setTimeout(() => {
                setEmailSentApplicantId(null);
            }, 3000);
    
            onClose(); // Close the popup after successful email sending
        } catch (error) {
            console.error("Error sending email and updating interview status:", error);
            alert("Failed to send email. Please try again.");
        } finally {
            setLoadingApplicantId(null); // Stop the loading indicator
        }
    };

    if (!isPopupVisible) return null;

    return (
        <div className="popup-overlay">
            <div className="popup-content" style={{ width: "750px" }}>
                <button
                    className="close-popup-button"
                    onClick={onClose}
                >
                    &times;
                </button>
                <h2>Interview with Nora Invite</h2>

                <label htmlFor="subject">Subject Line *</label>
                <input
                    type="text"
                    id="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    style={{
                        marginBottom: "10px",
                        padding: "10px",
                        width: "100%",
                    }}
                />

                <label htmlFor="content">Content *</label>
                <Editor
                    apiKey="7wf0mtg4eoef8ulv8xginyjk23eyv5e9a0midfl7vqmevm26"
                    value={content}
                    onEditorChange={(content) => setContent(content)}
                    init={{
                        height: 350,
                        menubar: false,
                        plugins: [
                            "advlist",
                            "autolink",
                            "lists",
                            "link",
                            "charmap",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "code",
                            "fullscreen",
                            "insertdatetime",
                            "table",
                            "code",
                            "help",
                            "wordcount",
                        ],
                        toolbar:
                            "undo redo | blocks | bold italic forecolor | " +
                            "alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help",
                        content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                />

                <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
                    <button
                        className="send-email-button"
                        onClick={handleSendEmail}
                        style={{ width: "20%", marginRight: "10px" }}
                    >
                        Send
                    </button>
                    <span style={{ margin: "0 10px", fontWeight: "bold", marginTop: "10px", marginLeft: "7%", marginRight: "8%" }}>OR</span>

                    <div style={{ display: "flex", flexDirection: "column", marginTop: "20px", width: "60%" }}>
                        <span style={{ fontWeight: "bold", marginBottom: "10px" }}>
                            Send this link to your candidates
                        </span>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <input
                                type="text"
                                value={interviewURL}
                                readOnly
                                style={{
                                    flex: "1",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                    marginRight: "10px",
                                }}
                            />
                            <button
                                className="jobModal-copy-button"
                                onClick={copyToClipboard}
                                style={{
                                    padding: "10px 15px",
                                    backgroundColor: "#4CAF50",
                                    color: "white",
                                    border: "none",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                }}
                            >
                                {copySuccess ? "Copied!" : <span className="clipboard-icon">&#x1F4CB;</span>}
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default InterviewWNoraPopup;