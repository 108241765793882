import React, { useEffect, useRef } from 'react';
import UserMessage from '../UserMessage';
import styles from './MessagesContainer.module.css';
import Logo from '../../../images/logo-circle.svg';
import BotMessage from '../BotMessage';
import Suggestions from '../Suggestions';
import Loader from "../Loader/loaders";

export const MessagesContainer = ({ messages, sendMessage, loading }) => {
  const lastElement = useRef(null);

  useEffect(() => {
    if (lastElement.current != null) {
      lastElement.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  let messagesJSX = (
    <div className={styles.container}>
      {messages.map((message) =>
        message.role === 'User' ? (
          <UserMessage key={message.key}>{message.message}</UserMessage>
        ) : (
          <BotMessage key={message.key}>{message.message}</BotMessage>
        )
      )}
      {loading && <Loader />}
      <div ref={lastElement} />
    </div>
  );
  if (messages.length === 0) {
    messagesJSX = (
      <div className={styles.container} style={{ alignItems: 'center' }}>
        <img src={Logo} className={styles.logo} />
        <p className={styles.greeting}>Hello, how can I help you today?</p>
        <Suggestions sendMessage={sendMessage} />
      </div>
    );
  }
  return messagesJSX;
};
