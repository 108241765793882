import React, {useState, useEffect, useRef} from 'react';
import UCLA from '../../images/homepage/ucla.png';
import BROWN from '../../images/homepage/brown.svg';
import MIT from '../../images/homepage/mit.png';
import UNI_TEXAS from '../../images/homepage/university_texas.png';
import UCI from '../../images/homepage/uci.png';
import 'react-multi-carousel/lib/styles.css';

import Chatbot from '../../components/Chatbot';
import CMU from '../../images/homepage/cmu.png';
import COLUMBIA from '../../images/homepage/columbia.png';
import CORNELL from '../../images/homepage/cornell.png';
import HARVARD from '../../images/homepage/harvard.png';
import NYU from '../../images/homepage/nyu.png';
import RUTGERS from '../../images/homepage/rutgers.png';
import UPENN from '../../images/homepage/upenn.png';

import FINVEST from '../../images/homepage/finvest.png';
import CRADLEWISE from '../../images/homepage/cradlewise.png';
import MERLIN from '../../images/homepage/merlin.png';
import NUTRIPAIR from '../../images/homepage/nutripair.png';
import LIMON from '../../images/homepage/limon.png';
import DONUT from '../../images/homepage/donut.png';
import KEYZII from '../../images/homepage/keyzii.png';
import LOCKERVERSE from '../../images/homepage/lockerverse.png';
import SHADES from '../../images/homepage/shadesNews.png';
import LEAPLABS from '../../images/homepage/leaplabs.png';
import QUILL from '../../images/homepage/quill.png';
import FORTE from '../../images/homepage/forte.png';
import VIRUFY from '../../images/homepage/virufy.png';
import BLUEPOND from '../../images/homepage/bluepond.png';
import NEOBOARD from '../../images/homepage/neoboard.png';
import NIRAXX from '../../images/homepage/niraxx.png';
import TOKKITENNIS from '../../images/homepage/tokkisports.png';
import PETE from '../../images/homepage/peteLearning.png';
import SIMPLECITI from '../../images/homepage/simpleCiti.png';

import GOOGLEREVIEW from '../../images/homepage/googleReview.png';

import HOWITWORKS1 from '../../images/homepage/howitworks1.png';
import HOWITWORKS2 from '../../images/homepage/howitworks2.png';
import HOWITWORKS3 from '../../images/homepage/howitworks3.png';

import LIMONCARD from '../../images/homepage/limoncard.svg';
import LIMONCEOCARD from '../../images/homepage/limonceocard.png';
import FORTECEO from '../../images/homepage/forteceo.png';
import FORTECARD from '../../images/homepage/fortecard.svg';
import SHADESCARD from '../../images/homepage/shadescard.svg';
import SHADESCEO from '../../images/homepage/shadesceo.png';
import FINVESTCEO from '../../images/homepage/finvestceo.svg';
import FINVESTCARD from '../../images/homepage/finvestcard.svg';
import PRICING1 from '../../images/homepage/pricing1.svg';
import PRICING2 from '../../images/homepage/pricing2.svg';

import MOBILE_PRICING1 from "../../images/homepage/mobile_pricing1.svg";
import MOBILE_PRICING2 from "../../images/homepage/mobile_pricing2.svg";

import './styles.css';
import Footer from '../../components/footer';
import { InlineWidget } from 'react-calendly';

import NavigationBar from '../../components/navigation';
import { useMediaQuery } from '@mui/material';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';

export default function HomePage() {
  const [chatbotOpen, setChatbotOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');

  const scrollToComponentFive = () => {
    document.getElementById('component_five').scrollIntoView({ behavior: 'smooth' });
  };  

  const [openIndex, setOpenIndex] = useState(null);
  const faqRefs = useRef([]);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    faqRefs.current.forEach((ref, idx) => {
      if (ref) {
        ref.style.maxHeight = openIndex === idx ? `${ref.scrollHeight}px` : '60px';
      }
    });
  }, [openIndex]);

  const faqs = [
    {
      question: "Are interns only for summer?",
      answer: "We provide interns year round. With a talent pool of 2.8 million students - you can be specific with your intern requirements :)"
    },
    {
      question: "How much does this cost?",
      answer: "We charge $800/intern. No other fees and we don't charge students. Also, it's fully refundable if things don't work out!"
    },
    {
      question: "How much do I pay interns?",
      answer: "Compensation of interns is up to you. We see - Pay by the hour, stipend and volunteer. But we're always team pay-the-interns!"
    },
    {
      question: "What interns can I get?",
      answer: "Pretty much anything. We have a talent pool of 2.8 million students - SWE, UI/UX, Sales, Marketing, Finance, etc. We got it all."
    }
  ];

  return (
    <div className="hero_container">
      <Chatbot open={chatbotOpen} closeDialog={() => setChatbotOpen(false)}/>
      <NavigationBar />

      <div className="ai-label">
        <span className="new-tag">NEW</span> 
        <span className="ai-text">AI powered recruiting</span>
      </div>

      <h1 className="hero_title">
        Your Startup's All-In-One Intern Hiring Solution
      </h1>
      <p className="hero_subtitle">
        The only platform to outsource intern hiring
      </p>
      <div className="btn-container">
      <button className="demo_request_button" onClick={() => scrollToComponentFive()}>
              Request a demo
      </button>
      <button className="job_description_button" onClick={() => setChatbotOpen(true)}>Create a Job Posting</button>
      </div>

      {/* Logo slider */}
      <div className="logo-slider">
        <div className="trusted-text">
          Trusted by 100+ top startups and small businesses
        </div>
        <div className="logo-track">
          {/* Repeat the logos twice for a continuous effect */}
          {[...Array(2)].map(() => (
            <>
              <div className="logo-item">
                <img src={CRADLEWISE} alt="Cradlewise" />
              </div>
              <div className="logo-item">
                <img src={FINVEST} alt="Finvest" />
              </div>
              <div className="logo-item">
                <img src={MERLIN} alt="Merlin" />
              </div>
              <div className="logo-item">
                <img src={NUTRIPAIR} alt="Nutripair" />
              </div>
              <div className="logo-item">
                <img src={LIMON} alt="Limon" />
              </div>
              <div className="logo-item">
                <img src={DONUT} alt="Donut" />
              </div>
              <div className="logo-item">
                <img src={KEYZII} alt="Keyzii" />
              </div>
              <div className="logo-item">
                <img src={LOCKERVERSE} alt="Lockerverse" />
              </div>
              <div className="logo-item">
                <img src={SHADES} alt="Shades News" />
              </div>
              <div className="logo-item">
                <img src={LEAPLABS} alt="Leap Labs" />
              </div>
              <div className="logo-item">
                <img src={QUILL} alt="Quill" />
              </div>
              <div className="logo-item">
                <img src={FORTE} alt="Forte" />
              </div>
              <div className="logo-item">
                <img src={VIRUFY} alt="Virufy" />
              </div>
              <div className="logo-item">
                <img src={BLUEPOND} alt="Bluepond" />
              </div>
              <div className="logo-item">
                <img src={NEOBOARD} alt="Neoboard" />
              </div>
              <div className="logo-item">
                <img src={NIRAXX} alt="Niraxx" />
              </div>
              <div className="logo-item">
                <img src={TOKKITENNIS} alt="Tokki Tennis" />
              </div>
              <div className="logo-item">
                <img src={PETE} alt="Pete Learning" />
              </div>
              <div className="logo-item">
                <img src={SIMPLECITI} alt="Simple Citi Holding" />
              </div>
            </>
          ))}
        </div>
      </div>

      <div className="hiring-stats-container">
        <div className="stats-content">
          <span className="stats-heading">
            We've facilitated over 250+ intern hires across all fields
          </span>
          <div className="google-review">
            <img src={GOOGLEREVIEW} alt="Google Review" />
          </div>
        </div>
        <div className="fields-container">
          <span className="field">UI/UX</span>
          <span className="field">SWE</span>
          <span className="field">Marketing</span>
          <span className="field">Campus Ambassador</span>
          <span className="field">Investment Banking</span>
          <span className="field">PR</span>
          <span className="field">Sales</span>
        </div>
      </div>

      <div className="network-section">
        <div className="network-content">
          <div className="network-text">
            <h2 className="network-heading">Tap into our network</h2>
            <p className="network-description">
              Intern Guys leverages our connections to a vast talent pool of 2.8
              million college students and graduates from top universities
              nationwide
            </p>
            <button className="demo_request_button" onClick={() => scrollToComponentFive()}>
              Request a demo
            </button>
          </div>
          <div className="university-partnership">
            <div className="trusted-text">
              Here's where some of our interns graduate from
            </div>
            <div className="university-logos">
              <div className="university-slide">
                {[
                  MIT,
                  UCLA,
                  BROWN,
                  UNI_TEXAS,
                  UCI,
                  CMU,
                  COLUMBIA,
                  CORNELL,
                  HARVARD,
                  NYU,
                  RUTGERS,
                  UPENN,
                ].map((logo, index) => (
                  <div key={index} className="university-logo-item">
                    <img src={logo} alt="University Logo" />
                  </div>
                ))}
              </div>
              <div className="university-slide">
                {[
                  MIT,
                  UCLA,
                  BROWN,
                  UNI_TEXAS,
                  UCI,
                  CMU,
                  COLUMBIA,
                  CORNELL,
                  HARVARD,
                  NYU,
                  RUTGERS,
                  UPENN,
                ].map((logo, index) => (
                  <div key={index} className="university-logo-item">
                    <img src={logo} alt="University Logo" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="outsourcing-solution">
        <h2 className="outsourcing-heading">Our Outsourcing Solution</h2>
        <p className="outsourcing-description">
          Intern recruiting made easy. Leave it all to us.
        </p>
      </div>

      <div className="how-it-works-1-section">
        <div className="how-it-works-1-content">
          <div className="how-it-works-1-text">
            <h2 className="how-it-works-1-heading">
              Tell us your intern requirements
            </h2>
            <p className="how-it-works-1-description">
              Technical skills, location, part time, full time, driving license
              - we mean every single requirement.
            </p>
          </div>
          <div className="how-it-works-1-partnership">
            <img src={HOWITWORKS1} alt="How it works 1" className='image1-styling'/>
          </div>
        </div>
      </div>

      <div className="how-it-works-2-section">
        <div className="how-it-works-2-content">
        <div className="how-it-works-1-text">
            <h2 className="how-it-works-2-heading">Outsource hiring to us</h2>
            <p className="how-it-works-2-description">
              Based off your requirements we'll start the hunt for the interns.
              We'll aggressively source, filter our pool of 2.8 million talented
              students and interview them to get you top candidates.
            </p>
          </div>
          <div className="how-it-works-1-partnership">
            <img src={HOWITWORKS2} alt="How it works 2" className='image2-styling'/>
            <p className="how-it-works-2-sub-description">
              Names and pictures of our talent have been changed to protect
              their privacy.
            </p>
          </div>
        </div>
      </div>

      <div className="how-it-works-3-section">
        <div className="how-it-works-1-content">
          <div>
            <h2 className="how-it-works-1-heading">You take over</h2>
            <p className="how-it-works-3-description">
              In 10 days, we'll have our top 3 candidates ready for you.
              Interview and hire them. If not we'll get you another set of 3
              candidates.
            </p>

            {!isMobile && (
              <button className="demo_request_button" onClick={() => scrollToComponentFive()}>
                Request a demo
              </button>
            )}
            
          </div>
          <div className="how-it-works-1-partnership">
            <img src={HOWITWORKS3} alt="How it works 3" className='image3-styling'/>
          </div>
        </div>
      </div>

      <div className="cta-container">
        <div className="cta-text">Startups love Intern Guys</div>
        <button className="demo_request_button" onClick={() => scrollToComponentFive()}>
              Request a demo
        </button>
      </div>
      <div className="carousel">
        <Swiper
          spaceBetween={20}
          slidesPerView={isMobile ? 1: 3}
          modules={[Pagination]}
          pagination={{ clickable: true }}
          style={{ maxWidth: '1500px', margin: 'auto', paddingBottom: '70px' }}
        >
          <SwiperSlide>
            <div className="card">
              <div className="card-top">
                <img
                  src={LIMONCEOCARD}
                  alt="Limon Founder Photo"
                  className="founder-image"
                />
                <img
                  src={LIMONCARD}
                  alt="Limon Logo"
                  className="company-logo"
                />
              </div>
              <div className="card-content">
                <h3>Lili Montes</h3>
                <p className="title">Co-Founder & CEO, Limon</p>
                <p className="quote">
                  “Intern Guys is a game changer. I've been using their service
                  for over 2 years and now I no longer spend any time on hiring.
                  The most reliable intern-finding service out there”
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card">
              <div className="card-top">
                <img
                  src={FORTECEO}
                  alt="Forte Founder Photo"
                  className="founder-image"
                />
                <img
                  src={FORTECARD}
                  alt="Forte Logo"
                  className="company-logo"
                />
              </div>
              <div className="card-content">
                <h3>Hunter McGranahan</h3>
                <p className="title">Founder, Forte</p>
                <p className="quote">
                  “Intern Guys has made hiring interns super simple. We just
                  provide our intern requirements, and within 10 days, they
                  prepare three final candidates for us to choose from”
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card">
              <div className="card-top">
                <img
                  src={SHADESCEO}
                  alt="Shades Founder Photo"
                  className="founder-image"
                />
                <img
                  src={SHADESCARD}
                  alt="Shades Logo"
                  className="company-logo"
                />
              </div>
              <div className="card-content">
                <h3>Jeff Grimes</h3>
                <p className="title">Co-Founder, Shades News</p>
                <p className="quote">
                  “I highly recommend Intern Guys for any high-growth business.
                  They're the best solution I have found for hiring interns. We
                  now save hours on the hiring process and have access to
                  top-tier interns.”
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="card">
              <div className="card-top">
                <img
                  src={FINVESTCEO}
                  alt="Finvest Founder Photo"
                  className="founder-image"
                />
                <img
                  src={FINVESTCARD}
                  alt="Finvest Logo"
                  className="company-logo"
                />
              </div>
              <div className="card-content">
                <h3>Shivam Bharuka</h3>
                <p className="title">CEO, Finvest (YC '22)</p>
                <p className="quote">
                  “I asked Intern Guys to find us 2 marketing interns, and we
                  ended up hiring 7. Outsource your hiring to Intern Guys and
                  watch them work their magic!”
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="custom-solutions-container">
        <div className="custom-solutions-left">
          Customized solutions built for YOU
        </div>
        <div className="custom-solutions-right">
          With Intern Guys, pay for what you want! Our product plans provide
          companies the flexibility and freedom to find the perfect interns
          their way.
        </div>
      </div>

      <div className="pricing-container">
        {!isMobile && (
            <div className="features">
            <h2>Features</h2>
            <p>Completely outsourced sourcing, recruiting, and interviewing</p>
            <p>Unlimited posts on platform</p>
            <p>Basic filtering of candidates</p>
            <p>Applicants emailed to you</p>
          </div>
        )}
      
        <div className="plans">
          <div onClick={scrollToComponentFive} className="clickable-card">
            <img src={isMobile ? MOBILE_PRICING1 : PRICING1} alt="Outsourcing Solution Plan" />
          </div>
          <div onClick={scrollToComponentFive} className="clickable-card">
            <img src={isMobile ? MOBILE_PRICING2 : PRICING2} alt="Post On Our Platform Plan" />
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="faq-section">
        <h2 style={{ fontWeight: 'bold', fontSize: '30px', marginBottom: '30px' }}>FAQ</h2>
        {faqs.map((faq, index) => (
          <div
            className={`faq-item ${openIndex === index ? 'open' : ''}`}
            key={index}
            ref={el => faqRefs.current[index] = el}
            onClick={() => toggleFAQ(index)}
            style={{ maxHeight: '60px' }} // Set initial maxHeight
          >
            <div className="faq-question">
              {faq.question}
              <span className="faq-toggle">{openIndex === index ? '▼' : '▲'}</span>
            </div>
            <div className="faq-answer">{faq.answer}</div>
          </div>
        ))}
      </div>


      <div className="component_five_container">
        <h1 className="calendly_header" id="component_five">Book a free call with us today!</h1>
        <div className="calendly_subtext">
          Learn how Intern Guys can help you make finding interns easy.
        </div>
        <div className="calendly_container">
          <div className="calendly_inner_container">
            <InlineWidget
              styles={{
                position: 'absolute',
                top: '0',
                bottom: '0',
                width: '100%',
              }}
              pageSettings={{
                backgroundColor: 'fff',
                hideEventTypeDetails: false,
                hideLandingPageDetails: false,
                primaryColor: '00a2ff',
                textColor: '000',
              }}
              url="https://calendly.com/internguys/intern-guys-demo-questionnaire"
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
