import React from 'react'
import styles from './UserMessage.module.css';

const UserMessage = ({children}) => {
  return (
    <div className={styles.container}>
      <p>{children}</p>
    </div>
  )
}

export default UserMessage;
