import React, { useState } from "react";
import "./style.css"; 
import checkCircle from "../../images/checkCircle.png";

function JobPostingPopup({ jobTitle, jobLink, onClose }) {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(jobLink);
    setCopySuccess(true);

    // Reset copy success after 2 seconds
    setTimeout(() => setCopySuccess(false), 2000);
  };

  return (
    <div className="jobModal-overlay">
      <div className="jobModal-container">
        <button className="jobModal-close-button" onClick={onClose}>
          &times;
        </button>
        <div className="jobModal-content">
          <img
            src={checkCircle}
            alt="Check Circle"
            className="jobModal-logo"
          />
          <h2 className="jobModal-title">{jobTitle}</h2>
          <p className="jobModal-subtitle">
            Share your job posting with the job link below.
          </p>
          <div className="jobModal-link-container">
            <input
              type="text"
              className="jobModal-link"
              value={jobLink}
              readOnly
            />
            <button className="jobModal-copy-button" onClick={copyToClipboard}>
              {copySuccess ? "Copied!" : <span class="clipboard-icon">&#x1F4CB;</span>} 
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobPostingPopup;