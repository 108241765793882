import React, { useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './Chatbot.module.css';
import { MessageInput } from './MessageInput';
import { MessagesContainer } from './MessagesContainer';
import { faX } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const Chatbot = ({ closeDialog, open }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const sendMessage = async (message) => {
    setMessages((prevState) => [
      ...prevState,
      { role: 'User', message, key: new Date().getTime() },
    ]);
    setLoading(true);
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/chat/chat`,
      {
        message,
        history: messages.map((msg) => ({role: msg.role, message: msg.message})),
      }
    );
    setLoading(false);
    setMessages((prevState) => [
      ...prevState,
      {
        role: 'Assistant',
        message: response.data.response,
        key: new Date().getTime(),
      },
    ]);
  };

  return (
    <Modal
      className={styles.container}
      style={{ overlay: { zIndex: 9000, backgroundColor: 'transparent' } }}
      isOpen={open}
      appElement={document.getElementById('root') || undefined}
    >
      <button className={styles.back} onClick={closeDialog}>
        <FontAwesomeIcon icon={faX} />
      </button>
      <MessagesContainer messages={messages} loading={loading} sendMessage={sendMessage} />
      <MessageInput sendMessage={sendMessage} />
    </Modal>
  );
};

export default Chatbot;
