import React from "react";
import "./style.css"

function Spinner()
{
    return (
        <div className="loader3">
            <div className="loader2"></div>
        </div>
    );
}

export default Spinner;