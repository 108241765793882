import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';

function AboutRecruiter() {
    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    
    function redirectCompany(e) {
        e.preventDefault();
        navigate('/aboutCompany');
        localStorage.setItem("firstName", firstName);
        localStorage.setItem("lastName", lastName);
        localStorage.setItem("email", email);
        localStorage.setItem("phoneNumber", phoneNumber);
    }

    return (
        <div className="about-container">

            <div className="about-box">

                <header className="about-flex-header">
                    <div className="about-header-item">
                        <div className="about-circle1"></div>
                        <div className="about-you">About You</div>
                    </div>

                    <div className="about-header-item about-line"></div>
                    
                    <div className="about-header-item">
                        <div className="about-circle2"></div>
                        <div className="about-work-authorization">About Your Company</div>
                    </div>
                </header>

                <section className="about-section">
                <h1 className="about-h1">About You</h1>
                    <form className="about-form" onSubmit={(e)=>redirectCompany(e)}>
                        <div className="about-form-group">
                            <label className="about-label">First Name</label>
                            <input className="about-input1" type="text" onChange={e => setFirstName(e.target.value)}></input>
                        </div>

                        <div className="about-form-group">
                            <label className="about-label">Last Name</label>
                            <input className="about-input1" type="text" onChange={e => setLastName(e.target.value)}></input>
                        </div>

                        <div className="about-form-group">
                            <label className="about-label">Email</label>
                            <input className="about-input1" type="text" onChange={e => setEmail(e.target.value)}></input>
                        </div>

                        <div className="about-form-group">
                            <label className="about-label">Phone Number</label>
                            <input className="about-input1" type="text" onChange={e => setPhoneNumber(e.target.value)}></input>
                        </div>

                        <div className="about-form-button">
                            <button className="about-button" type="submit" value="Submit">Next</button>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    )
}

export default AboutRecruiter;