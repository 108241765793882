import React from 'react'
import "./style.css"
function PortalNav() {
  return (
    <div className="portal-nav-container">
        <div className="portal-nav-text-container">
            <a href="https://www.internguys.com/">Students</a>
            <a >Recruiters</a>
        </div>
    </div>
  )
}

export default PortalNav