import React from "react";
import "../../Profile.css";
import profilePic from "../profile-pic.jpg";

const RecruiterProfileUser = (props) => {
  return (
    <div>
    <div className="d-flex flex-row recruiter-profile-user__main-div">
        <img className="rounded-circle recruiter-profile-user__profile-img" alt="profile picture" src={profilePic} />
      <div className="recruiter-profile-user__name-div">
          
          {props.recruiterName!==null?(<h1 className="recruiter-profile-user__name">{props.recruiterName}</h1>):(<h1 className="recruiter-profile-user__name">Albert Clark</h1>)}
          <span className="recruiter-profile-user__location">
            {props.companyName!==null?(<b>{props.companyName}</b>) : (<b>Intern Guys</b>)}
            {props.companyLocation!==null? (<>&#183; {props.companyLocation}</>) : (<> &#183; Irvine, CA</>)}
            </span>
          <div className="d-flex flex-row mt-5 profile-user__btn-group">
            <button className="profile-user__btn--chosen me-3">Recruiter</button>
            <button onClick={props.onCompanyBtnClicked} className="profile-user__btn ms-3">Company</button>
          </div>
      </div>
    </div>
    </div>
  );
};

export default RecruiterProfileUser;
